import {
  camelToSnake,
  convertCamelToSnake,
  snakeToCamel,
} from "@/application/util/keyConverter";
import Qs from "qs";

export const transformResponse = (response: string | unknown): unknown => {
  if (typeof response === "string" && response.length > 0) {
    try {
      const result = JSON.parse(response);
      return snakeToCamel(result);
    } catch (e) {
      return response;
    }
  }

  return response;
};

export const transformRequest = (
  data: Record<string, unknown> | FormData
): Record<string, unknown> | FormData => {
  if (!data) {
    return data;
  }

  if (data instanceof FormData) {
    const newData = new FormData();
    for (const pair of data.entries()) {
      newData.append(convertCamelToSnake(pair[0]), pair[1]);
    }
    return newData;
  }

  if (typeof data.mapForRequest === "function") {
    data = data.mapForRequest(); //@TODO it this the way i want to use models?
  }

  return camelToSnake(data as Record<string, unknown>);
};

export const transformParams = (params: Record<string, unknown>) => {
  /**
   * for now i'm just replacing true/false with 1/0 in the request body we are using a function
   * that loops recursive trough all the values, except i'm guessing this is not needed for the querystring
   * at this time. We could always replace this code with something like objectToFormDataConverter
   */
  const loop = (params: Record<string, unknown>) => {
    const newParams: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(params)) {
      let newValue: unknown = value;
      if (value === true) {
        newValue = 1;
      }
      if (value === false) {
        newValue = 0;
      }
      if (typeof value === "object" && value !== null) {
        newValue = loop(value as Record<string, unknown>);
      }
      newParams[key] = newValue;
    }
    return newParams;
  };
  return Qs.stringify(camelToSnake(loop(params)));
};
