import { get } from "@/application/api";
import { AxiosResponse } from "axios";

export interface ProfileResponse {
  data: {
    id: number;
    email: string;
    leadReimbursement: string;
    url?: string;
  };
}

export const profile = (): Promise<AxiosResponse<ProfileResponse>> => {
  return get("profile");
};
