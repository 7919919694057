export const camelToSnake = (
  data: Record<string, unknown>
): Record<string, unknown> => {
  if (!isIterableObject(data)) {
    throw new Error("keyConverter::camelToSnake data is not iterable");
  }

  const target = Array.isArray(data) ? [] : {};

  Object.keys(data).forEach((key) => {
    Object.assign(target, {
      [convertCamelToSnake(key)]: isIterableObject(data[key])
        ? camelToSnake(data[key] as Record<string, unknown>)
        : (data[key] as unknown),
    });
  });

  return target;
};

export const convertCamelToSnake = (key: string): string => {
  /**
   * Regex should not camel to snake if:
   * * between a figure (*12 should be *_12, not _1_2)
   * * key starts with capital or figure (snake_case, not _snake_case)
   */

  //if only uppercase, numbers and underscores, then it's already snake_case
  if (/^[A-Z0-9_]+$/.test(key)) {
    return key;
  }

  return key
    .replace(/(?:^|\.?)([A-Z]|\d+)/g, (_, value) => `_${value.toLowerCase()}`)
    .replace(/^_/, "")
    .replace(/__/, "_");
};

export const snakeToCamel = (
  data: Record<string, unknown>
): Record<string, unknown> => {
  if (!isIterableObject(data)) {
    throw new Error("keyConverter::snakeToCamel data is not iterable");
  }

  const target = Array.isArray(data) ? [] : {};

  Object.keys(data).forEach((key) => {
    Object.assign(target, {
      [convertSnakeToCamel(key)]: isIterableObject(data[key])
        ? snakeToCamel(data[key] as Record<string, unknown>)
        : (data[key] as unknown),
    });
  });

  return target;
};

export const convertSnakeToCamel = (key: string): string => {
  // If it's only capitals and numbers, then ignore it
  if (/^[A-Z0-9_]+$/.test(key)) {
    return key;
  }
  return key.replace(/_\w/g, (value) => value[1].toUpperCase());
};

export const isIterableObject = (target: unknown): boolean => {
  return (
    typeof target === "object" &&
    target !== null &&
    !(target instanceof Blob) &&
    !(target instanceof Date)
  );
};
