import store from "@/application/store/index";
import router from "@/router/index";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isAxiosError } from "@/application/util/isAxiosError";

export const onRequestFulFilled = (
  request: AxiosRequestConfig
): AxiosRequestConfig => {
  const computedHeaders = computeHeaders();

  Object.keys(computedHeaders).forEach((header) => {
    if (!request || !request.headers || !request.headers.common) return;
    Object.assign(request.headers.common, {
      [header]: computedHeaders[header],
    });
  });

  return request;
};

function computeHeaders(): Record<string, unknown> {
  return {
    Authorization: store.getters["authorisation/isLoggedIn"]
      ? `Bearer ${store.getters["authorisation/getAuthorisationToken"]}`
      : undefined,
  };
}

export const onRequestRejected = (error: AxiosError): AxiosError => {
  return error;
};

export const onResponseFulFilled = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const onResponseRejected = (error: AxiosError) => {
  const { response } = error;

  if (!response || !isAxiosError(error)) return Promise.reject(error); // network error, not axios related

  const { status } = response;
  const { errors } = response.data as Record<string, any>;

  if (status === 401) {
    store.dispatch("authorisation/logout");
    location.reload();
    return;
  } else if (status === 403) {
    router.push({ name: "403" });
  }

  if (errors && status === 422) {
    Object.keys(errors).forEach((key) =>
      store.commit("error/add", {
        key,
        message: errors[key][0],
      })
    );
  }

  return Promise.reject(error);
};
