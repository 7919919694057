// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Commit } from "vuex";
import { profile } from "@/modules/authorisation/api/profile";

const initialState = {
  token: "",
  profile: undefined,
};

interface Profile {
  id?: number;
  email?: string;
  leadReimbursement?: string;
  url?: string;
}

interface State {
  token: string;
  profile?: Profile;
}

export default {
  namespaced: true,
  state: initialState as State,
  mutations: {
    setAuthorisationToken(state: State, token: string): void {
      state.token = token;
    },
    setProfile(state: State, profile: Record<string, unknown>): void {
      state.profile = profile;
    },
    resetAuthorisation(state: State): void {
      Object.keys(state).forEach((key) => delete state[key as keyof State]);
      Object.assign(state, initialState);
    },
  },
  actions: {
    logout({ commit }: { commit: Commit }): void {
      commit("resetAuthorisation");
    },
    async fetchProfile({ commit }: { commit: Commit }): Promise<void> {
      const result = await profile();

      commit("setProfile", {
        id: result.data.data.id,
        leadReimbursement: result.data.data.leadReimbursement,
        url: result.data.data.url,
        email: result.data.data.email,
      });
    },
  },
  getters: {
    isLoggedIn: (state: State): boolean => !!state.token.length,
    profile: (state: State): Profile | undefined => state.profile,
    userId: (state: State): number | undefined => state.profile?.id,
    getAuthorisationToken: (state: State): string => state.token,
  },
};
