interface State {
  errors: Error[];
}

interface Error {
  key: string;
  message: string;
}

export default {
  namespaced: true,
  state: {
    errors: [],
  } as State,
  mutations: {
    remove: (state: State, key: string) =>
      state.errors.splice(
        state.errors.findIndex((x) => x.key === key),
        1
      ),
    clear: (state: State) => {
      state.errors = [];
    },
    add: (state: State, { message, key }: Error): Error[] => {
      const target = state.errors.find((x) => x.key === key);

      if (!target) {
        state.errors.push({
          key,
          message,
        });
        return state.errors;
      }

      target.message = message;
      return state.errors;
    },
  },
  getters: {
    find: (state: State) => (key: string) => {
      const error = state.errors.find((x) => x.key === key);

      return error ? error.message : "";
    },
    first: (state: State) => {
      if (!state.errors.length) {
        return "";
      }

      return state.errors[0].message;
    },
  },
};
