import Vue from "vue";

interface State {
  routeSettings: Record<string, RouteSetting>;
}

export interface RouteSetting {
  pagination: {
    page: number;
    perPage: number;
    search: string;
    sortBy: Array<string>;
    descending: Array<boolean>;
    params: Record<string, any> | undefined;
  };
  isOpenFilter: boolean;
}

export default {
  namespaced: true,
  state: {
    routeSettings: {},
  },
  mutations: {
    setRouteSetting(
      state: State,
      { routeName, settings }: { routeName: string; settings: RouteSetting }
    ) {
      Vue.set(state.routeSettings, routeName, settings);
    },
  },
  getters: {
    getRouteSetting:
      (state: State) =>
      (routeName: string): RouteSetting | undefined =>
        state.routeSettings[routeName],
  },
};
