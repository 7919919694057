import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./application/store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";
import * as Sentry from "@sentry/vue";
import "@/scss/main.scss";
import { environment } from "@/application/api/baseUrl";

Vue.config.productionTip = false;

if ("development" !== process.env.NODE_ENV) {
  Sentry.init({
    Vue,
    dsn: "https://d39236b149af4361ac54f3cf49ed9524@o88312.ingest.sentry.io/6618136",
    environment: environment(),
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
