import {
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faGripVertical,
  faHouse,
  faKey,
  faMagnifyingGlass,
  faUpload,
  faUser,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";

const icons = [
  faHouse,
  faMagnifyingGlass,
  faUser,
  faUsers,
  faEye,
  faEyeSlash,
  faFile,
  faFileVideo,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFilePdf,
  faUpload,
  faFilter,
  faGripVertical,
  faCalendarAlt,
  faKey,
];

/*kingscode icons*/
const identifiers = {
  home: ["fas", "house"],
  profile: ["fas", "user"],
  search: ["fas", "magnifying-glass"],
  upload: ["fas", "upload"],
  filter: ["fas", "filter"],

  user: ["fas", "user"],
  users: ["fas", "users"],
  visible: ["fas", "eye"],
  invisible: ["fas", "eye-slash"],
  fileUnknown: ["fas", "file"],
  fileVideo: ["fas", "file-video"],
  fileWord: ["fas", "file-word"],
  fileExcel: ["fas", "file-excel"],
  filePowerpoint: ["fas", "file-powerpoint"],
  filePdf: ["fas", "file-pdf"],
  grip: ["fas", "grip-vertical"],

  date: ["fas", "calendar-alt"],
  userRole: ["fas", "key"],
};

export default {
  icons,
  identifiers,
};
