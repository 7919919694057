import i18n from "@/plugins/i18n";

/**
 * This function is meant for translating fields, as in crud fields
 */
export const translateField = (
  field: string,
  translationPrefix: string
): string => {
  if (translationPrefix) {
    const translationKey = `${translationPrefix}.${field}`;
    if (translationPrefix && i18n.te(translationKey)) {
      return i18n.t(translationKey) as string;
    }

    if (i18n.te(field)) {
      return i18n.t(field) as string;
    }

    return i18n.t(translationKey) as string;
  }

  return field;
};
