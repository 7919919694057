<template>
  <div>
    <v-form ref="form" v-model="isValid" @submit.prevent="handleLogin()">
      <v-alert
        :value="!!errorMessage.length"
        class="mb-10"
        transition="fade-transition"
        type="error"
      >
        {{ errorMessage }}
      </v-alert>
      <v-alert
        v-if="$route.query.message"
        class="mb-10"
        transition="fade-transition"
        type="success"
      >
        {{ $t($route.query.message) }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields">
        <KTextField
          v-model="form.email"
          autocomplete="username"
          autofocus
          field="email"
          tabindex="1"
        />
        <KTextField
          v-model="form.password"
          :append-icon="showPassword ? '$invisible' : '$visible'"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="current-password"
          field="password"
          tabindex="2"
          @click:append="showPassword = !showPassword"
        />
      </k-field-group>
      <v-row justify="end" no-gutters>
        <VBtn
          :loading="isLoading"
          tabindex="3"
          type="submit"
          block
          color="accent"
          v-text="$t('authorisation.actions.login')"
        />
      </v-row>
      <v-row no-gutters class="mt-3">
        <VBtn
          :to="{ name: 'password.forgotten' }"
          small
          tabindex="4"
          text
          color="primary"
          v-text="$t('authorisation.actions.passwordForgotten')"
        />
      </v-row>
    </v-form>
    <v-overlay v-model="isRedirecting" class="text-center">
      <VProgressCircular indeterminate size="64" />
      <div class="mt-5" v-text="$t('authorisation.login.successMessage')" />
    </v-overlay>
  </div>
</template>

<script lang="ts">
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { mapGetters } from "vuex";
import { login } from "@/modules/authorisation/api/login";
import { getThrottleErrorMinutes } from "@/application/util/ThrottleErrorResponse";
import postRedirect from "@/application/util/postRedirect";
import Vue, { VueConstructor } from "vue";
import { AxiosError, AxiosResponse } from "axios";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
        };
      };
    }
  >
).extend({
  name: "Login",
  components: {
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      errorMessage: "",
      isLoading: false,
      showPassword: false,
      isValid: false,
      form: {
        email: "",
        password: "",
      },
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      findError: "error/find",
    }),
  },
  methods: {
    async handleLogin(): Promise<void> {
      this.isLoading = true;
      try {
        const response = await login(this.form.email, this.form.password);
        this.isRedirecting = true;
        this.redirectToAuthDispense(response.data.data.token);
      } catch (error) {
        const { response } = error as AxiosError;
        const { status } = response as AxiosResponse;

        if (status === 429) {
          this.errorMessage = this.$t("errors.429", {
            minutes: getThrottleErrorMinutes(response as AxiosResponse),
          }) as string;
        } else {
          this.errorMessage = this.findError("email");
        }
        this.$refs.form.validate();
      } finally {
        this.isLoading = false;
      }
    },
    redirectToAuthDispense(token: string): void {
      const redirect = this.$route.query.redirect?.includes("logout")
        ? ""
        : this.$route.query.redirect;
      postRedirect("auth/dispense", {
        redirect_uri: (redirect as string) || "/dashboard",
        email: this.form.email,
        token: token,
      });
    },
  },
});
</script>
