import Login from "@/modules/authorisation/views/Login.vue";

export default {
  authorised: [],
  authorisation: [
    {
      path: "login",
      name: "login",
      component: Login,
    },
    {
      path: "auth/callback",
      name: "auth.callback",
      component: () =>
        import("@/modules/authorisation/views/AuthorisationCallback.vue"),
    },
    {
      path: "password/forgotten",
      name: "password.forgotten",
      component: () =>
        import("@/modules/authorisation/views/PasswordForgotten.vue"),
    },
    {
      path: "password/reset/:token",
      name: "password.reset",
      component: () =>
        import("@/modules/authorisation/views/PasswordReset.vue"),
    },
    {
      path: "invitation/accept/:token",
      name: "invitation.accept",
      component: () =>
        import("@/modules/authorisation/views/InvitationAccept.vue"),
    },
  ],
};
