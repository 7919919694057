<template>
  <v-snackbar :value="progress >= 0" :timeout="-1">
    {{ text || $t("global.downloading") }}
    <VProgressLinear
      :buffer-value="progress"
      :value="progress"
      stream
      :color="progress !== 100 ? 'accent' : 'success'"
    />
  </v-snackbar>
</template>

<script lang="ts">
import eventBus from "@/application/eventBus";
import Vue from "vue";

interface ComponentData {
  progress: number;
  text: string;
}

export default Vue.extend({
  name: "TheGlobalProgress",
  data: (): ComponentData => ({
    progress: -1,
    text: "",
  }),
  watch: {
    progress(newValue: number) {
      if (newValue > 99) {
        setTimeout(() => {
          this.progress = -1;
        }, 3000);
      }
    },
  },
  created(): void {
    eventBus.$on(
      "download-progress",
      ({ percentage, text }: { percentage: number; text?: string }) => {
        this.progress = percentage;
        this.text = text || "";
      }
    );
    window.onbeforeunload = this.handleBeforeUnload;
  },
  methods: {
    handleBeforeUnload(event: BeforeUnloadEvent): void {
      if (this.progress >= 0 && this.progress < 100) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
  },
});
</script>
