import {
  faArrowDownLeft,
  faArrowUpRight,
  faDash,
  faFileSignature,
  faHouse,
} from "@fortawesome/pro-solid-svg-icons";

const icons = [
  faHouse,
  faArrowUpRight,
  faArrowDownLeft,
  faFileSignature,
  faDash,
];

/*project specific icons*/
const identifiers = {
  dashboard: ["fas", "house"],
  percentageUp: ["fas", "arrow-up-right"],
  percentageDown: ["fas", "arrow-down-left"],
  percentageZero: ["fas", "dash"],
  objectionRequest: ["fas", "file-signature"],
};

export default {
  icons,
  identifiers,
};
