export default {
  authorised: [
    {
      path: "temporary-objection-request",
      name: "temporaryObjectionRequest.index",
      component: () =>
        import(
          "@/modules/temporaryObjectionRequest/views/TemporaryObjectionRequestTable.vue"
        ),
    },
  ],
};
