import {
  faArrowsAltV,
  faBackwardStep,
  faBars,
  faCaretDown,
  faCheck,
  faCircleCheck,
  faChevronDown,
  faCircle as faSolidCircle,
  faExclamation,
  faForwardStep,
  faInfoCircle,
  faMinus,
  faPaperclip,
  faPencil,
  faPlus,
  faSortUp,
  faSquareCheck,
  faSquareMinus,
  faStar,
  faStarHalf,
  faSync,
  faTrashCan,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleDot,
  faSquare,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

const icons = [
  faMinus,
  faPlus,
  faPaperclip,
  faArrowsAltV,
  faForwardStep,
  faBackwardStep,
  faSync,
  faStar,
  faStarHalf,
  faPencil,
  faCircle,
  faSolidCircle,
  faCaretDown,
  faBars,
  faChevronDown,
  faSortUp,
  faSquareMinus,
  faSquare,
  faSquareCheck,
  faChevronRight,
  faChevronLeft,
  faTriangleExclamation,
  faExclamation,
  faInfoCircle,
  faCircleCheck,
  faXmark,
  faCheck,
  faTrashCan,
  faCircleDot,
];

/*vuetify default icons*/
const identifiers = {
  complete: ["fas", "check"],
  cancel: ["far", "xmark"],
  close: ["far", "xmark"],
  delete: ["fas", "trash-can"],
  clear: ["far", "xmark"],
  success: ["fas", "circle-check"],
  info: ["fas", "info-circle"],
  warning: ["fas", "exclamation"],
  error: ["fas", "triangle-exclamation"],
  prev: ["far", "chevron-left"],
  next: ["far", "chevron-right"],
  checkboxOn: ["fas", "square-check"],
  checkboxOff: ["far", "square"],
  checkboxIndeterminate: ["fas", "square-minus"],
  delimiter: ["fas", "circle"],
  sort: ["fas", "sort-up"],
  expand: ["fas", "chevron-down"],
  menu: ["fas", "bars"],
  subgroup: ["fas", "caret-down"],
  dropdown: ["fas", "caret-down"],
  radioOn: ["far", "circle-dot"],
  radioOff: ["far", "circle"],
  edit: ["fas", "pencil"],
  ratingEmpty: ["fas", "star"],
  ratingFull: ["fas", "star"],
  ratingHalf: ["fas", "star-half"],
  loading: ["fas", "sync"],
  first: ["fas", "backward-step"],
  last: ["fas", "forward-step"],
  unfold: ["fas", "arrows-alt-v"],
  file: ["fas", "paperclip"],
  plus: ["fas", "plus"],
  minus: ["fas", "minus"],
};

export default { icons, identifiers };
