import VueI18n from "vue-i18n";
import LocaleMessageObject = VueI18n.LocaleMessageObject;

interface RequireContext {
  keys(): string[];
  (id: string): any;
  <T>(id: string): T;
  resolve(id: string): string;
  /** The module id of the context module. This may be useful for module.hot.accept. */
  id: string | number;
}

export default function loadLocaleMessages(
  context: RequireContext
): LocaleMessageObject {
  const messages: LocaleMessageObject = {};

  context.keys().forEach((component) => {
    messages[filePathToBaseName(component)] = context(component);
  });

  return messages;
}

function filePathToBaseName(filePath: string): string {
  const matched = filePath.match(/([A-Za-z0-9-_]+)\./i);
  if (!matched) {
    throw new Error(`Could not extract basename from ${filePath}.`);
  }
  return matched[1];
}
