<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <v-card width="400px" max-width="100%">
        <v-card-text class="text-center">
          <VImg
            :src="require('@/assets/images/logo.svg')"
            alt="Bezwaarmaker logo"
            class="ma-auto"
            max-width="70%"
          />
          <h5 class="text-h6 black--text">
            {{ $t("authorisation.default.title") }}
          </h5>
          <h6 class="text--disabled font-weight-medium mb-10 text-subtitle-1">
            {{ $t("authorisation.default.subTitle") }}
          </h6>
          <router-view />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Authorisation",
});
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: #242939;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
